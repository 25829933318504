<template>
</template>
<script lang="ts">
import {useNuxtApp} from "#app";
import {useGtm} from "@gtm-support/vue-gtm";

export default defineComponent({
  data() {
    return {
    }
  },
  methods: {
    inicializarCookies() {
      this.nuxtApp.$fb.enable();
      this.gtm?.enable();
    },

  },
  mounted() {
    this.inicializarCookies();
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.xs
    }
  },

  setup() {
    const gtm = useGtm();
    const nuxtApp = useNuxtApp();
    return {
      nuxtApp,
      gtm
    }
  },
})
</script>
<style scoped>
</style>
